import React from 'react';
import '../App.css';

function Card(props) {
    console.log(props.img);
	return (
        <div className='Card'>
            <div className="Card-Text">{props.text}</div>
            <div className="Card-Text Card-Num">{props.num}</div>
            
            <img className="Card-Images" src={props.img} alt="IMG NOT FOUND"></img>
        </div>  
  );
}

export default Card;