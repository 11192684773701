import React from 'react';
import '../App.css';
import Card from './Card.js';

function MainPage(props) {

	return (
        <div className='Main-Page'>
            <Card text="Upcoming Reservations" num="25" img="https://i.ibb.co/2ZKRXTK/pic1.png" />
            <Card text="Servicers Hired" num="15" img="https://i.ibb.co/8PWkZSw/pic2.png" />
            <Card text="Pending job requests" num="12" img="https://i.ibb.co/ypwdNmT/pic3.png"/>

        </div>  
  );
}

export default MainPage;