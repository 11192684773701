import React from 'react';
//import Amplify from 'aws-amplify';
//import awsconfig from './aws-exports';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

import './App.css';

import Header from './components/Header.js';
import MainPage from './components/MainPage.js';

// Still needed?
//import logo from './logo.svg';
//import Card from './components/Card.js';

//Amplify.configure(awsconfig);

const App = () => (
    <div className="App">
      <AmplifySignOut />
      <Header />
      <MainPage />
    </div>
);

export default withAuthenticator(App);
