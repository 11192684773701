import React from 'react';
import '../App.css';


function GetDate() {
    var d = new Date();
    var months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    var days = ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"];
    var month = months[d.getMonth()];
    var dayOfWeek = days[d.getDay()-1];
    var day = d.getDate();
    var date = dayOfWeek + ", " + month + " " + day
    return (
      <div>
        <div className='Date'> {date} </div>
      </div>
    );
}


function WelcomeText() {
  var d = new Date();
  var welcomeText = "Good";
  var timeOfDay = "";
  var hours = d.getHours();
  if (hours >=0 && hours<= 12) {
      timeOfDay = "Morning";
  } else if (hours >=12 && hours < 18) {
      timeOfDay = "Afternoon";
  } else {
      timeOfDay = "Evening";
  }
  welcomeText += " " + timeOfDay + "!";
  return (
    <div className="Welcome-Text">
      {welcomeText}
    </div>  
  );
  
}

function Navbar() {
  return (
    <div className="Header-Text">
      <GetDate />
      <WelcomeText />
      Hey Jason, here is what going on <a href="http://www.google.com">TODAY</a>.
    </div>
  );
}

function Header() {

	return (
    <div className='Header'>
      <Navbar />
    </div>  
  );
}


export default Header;